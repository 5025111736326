<template>
  <div>
    <form enctype="multipart/form-data">
      <span class="input-file el-icon-picture">
        <input
          name="file"
          @change="changeImage($event)"
          type="file"
          ref="fileInput"
        />
      </span>
      <!-- <input @click="Upload" type="button" value="Upload" /> -->
      <!-- <img v-if="img" :src="img" alt="" /> -->
    </form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      img: "",
    };
  },
  methods: {
    // 回显预览
    changeImage(e) {
      // 调用上传接口
      this.Upload();
      // 获取文件
      var file = e.target.files[0];
      // 创建FileReader实例
      var reader = new FileReader();
      // 将文件读取为DataURL
      reader.readAsDataURL(file);
      // 文件读取成功完成后触发
      reader.onload = (e) => {
        // 将读取到的文件值赋值给img
        this.img = reader.result;
        // 文件读取之后
        this.$emit("previewImg", this.img);
      };
    },
    async Upload() {
      // 创建formData实例
      let formData = new FormData();
      // 将文件和token放入FormData
      // 获取到元素内传入的文件
      formData.append("file", this.$refs.fileInput.files[0]);
      formData.append(
        // "Token",
        // "507f056af74db9e9bdff3d4258e4f5593f6f1c1b:wNqKxjRXec62Y6prAvY5Pv4VCq4=:eyJkZWFkbGluZSI6MTYxNDIzOTg3NSwiYWN0aW9uIjoiZ2V0IiwidWlkIjoiNjcxNTQxIiwiYWlkIjoiMTc0NzM2OSIsImZyb20iOiJmaWxlIn0="
		"Token",
		
    "bug3e51dw94ppggcgjhslhd1rbw38qih:b9DLRiOwk5kJEk0AmjUpKfFgHuM=:eyJkZWFkbGluZSI6MTY0ODQ1OTIwMiwiYWN0aW9uIjoiZ2V0IiwidWlkIjoxMDA2MywiYWlkIjoiMjYwMjYiLCJmcm9tIjoiZmlsZSJ9"
      );
      const res = await this.$http({
        url: "tietu", //请求路径
        method: "post",
        data: formData,
        // 设置请求头
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.status == 200) {
        this.$emit("uploadImg", res.data);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input-file {
  font-size: 24px;
  color: #666;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  & > input {
    opacity: 0;
    filter: alpha(opacity=0);
    font-size: 100px;
    position: absolute;
    top: 0;
    right: 0;
  }
}
</style>